/* eslint-disable react-hooks/exhaustive-deps */
import { getAuth, signOut } from '@firebase/auth';
import { Timestamp, collection, doc, getFirestore, onSnapshot, orderBy, query, setDoc, where, or } from '@firebase/firestore';
import React, { useEffect, useRef, useState } from 'react';
import { playModes, scoringModes } from './Config';
import { getItemsFromQuerySnapshot } from './Utils';

export const AppContext = React.createContext({});

export const AppContextProvider = ({ children }) => {
    const [user, setUser] = useState();
    const [userData, setUserData] = useState();
    const [courses, setCourses] = useState();
    const [scoreCards, setScoreCards] = useState();
    const [scoreCardsInMatches, setScoreCardsInMatches] = useState();
    const [tournaments, setTournaments] = useState();
    const [users, setUsers] = useState();
    const [matches, setMatches] = useState();

    // ---------------------------------------------------------------

    const logoff = async () => {
        return signOut(getAuth());
    }

    const updateMatch = async theMatch => {

        const newDateSeconds = theMatch.date.toDate();
        const newMatch = {
            ...theMatch,
            ...{
                modifiedDate: Timestamp.fromDate(new Date()),
                date: Timestamp.fromDate(newDateSeconds),
                players: theMatch.scoreCards.map(scItem => scItem.userId)
            }
        };
        try {
            const newDoc = doc(getFirestore(), 'matches', theMatch.id);
            await setDoc(newDoc, newMatch);
        } catch (error) {
            console.log(error);
        }
    }

    const updateScoreCard = async theScoreCard => {

        const newDateSeconds = theScoreCard.date.toDate();
        const newScoreCard = {
            ...theScoreCard,
            ...{
                modifiedDate: Timestamp.fromDate(new Date()),
                date: Timestamp.fromDate(newDateSeconds),
            }
        };
        try {
            const newDoc = doc(getFirestore(), 'scoreCards', theScoreCard.id);
            await setDoc(newDoc, newScoreCard);
        } catch (error) {
            console.log(error);
        }
    }


    const addScoreCard = (scoreCardInitData, callback) => {
        const baseScoreCard = {
            date: Timestamp.fromDate(new Date()),
            event: '',
            courseName: '',
            courseId: '',
            teeId: '',
            holeCount: 0,
            holes: [],
            shotCount: 0,
            stableford: 0,
            handicap: 0,
            handicapPlay: 0,
            handicapGolf: +appContext.userData.handicapGolf,
            handicapPitchAndPutt: +appContext.userData.handicapPitchAndPutt,
            isCompetition: false,
            notes: '',
            greenFee: 0,
            usedBuggy: false,
            userId: appContext.user.uid,
            creatorId: appContext.user.uid
        }
        const newScoreCard = { ...baseScoreCard, ...scoreCardInitData };
        let newDoc;
        try {
            newDoc = doc(collection(getFirestore(), appContext.getScoreCardsPath()));
            setDoc(newDoc, newScoreCard).then(result => {
                if (callback) {
                    callback(newDoc)
                }
            })
        } catch (error) {
            console.log(error);
            if (callback) {
                callback(error)
            }
        }
    }

    const addMatch = (matchInitData, callback) => {
        const date = Timestamp.fromDate(new Date());
        const theMatch = {
            date: date,
            creationDate: date,
            modifiedDate: date,
            courseId: '',
            tournamentId: '',
            isCompetition: false,
            notes: '',
            scoreCards: [],
            creatorId: appContext.user.uid,
            playMode: playModes[0],
            scoringMode: scoringModes[0],
            userId: appContext.user.uid,
        }
        const newMatch = { ...theMatch, ...matchInitData };
        let newDoc;
        try {
            newDoc = doc(collection(getFirestore(), 'matches'));
            setDoc(newDoc, newMatch).then(result => {
                if (callback) {
                    callback(newDoc)
                }
            })
        } catch (error) {
            console.log(error);
            if (callback) {
                callback(error)
            }
        }
    }

    // ---------------------------------------------------------------

    const appContext = useRef({}).current;
    appContext.user = user;
    appContext.scoreCards = scoreCards;
    appContext.scoreCardsInMatches = scoreCardsInMatches;
    appContext.courses = courses;
    appContext.matches = matches;
    appContext.tournaments = tournaments;
    appContext.users = users;
    appContext.userData = userData;
    appContext.logoff = logoff;
    appContext.getCourseById = id => courses?.find(c => c.id === id);
    appContext.getScoreCardById = id => scoreCards?.find(sc => sc.id === id);
    appContext.getMatchById = id => matches?.find(m => m.id === id);
    appContext.getTournamentById = id => tournaments?.find(t => t.id === id);
    appContext.getUserById = id => users?.find(t => t.id === id);
    appContext.getScoreCardTee = (scoreCard) => {
        const course = appContext.getCourseById(scoreCard.courseId);
        return course ? course.tees.find(tee => (tee.id === scoreCard.teeId) || (tee.name === scoreCard.teeName)) : undefined;
    }
    appContext.addScoreCard = addScoreCard;
    appContext.addMatch = addMatch;
    appContext.updateScoreCard = updateScoreCard;
    appContext.updateMatch = updateMatch;
    appContext.getScoreCardsPath = () => {
        // return 'users/' + user?.uid + '/scoreCards';
        return '/scoreCards';
    }

    // ---------------------------------------------------------------

    getAuth().onAuthStateChanged(newUser => {
        setUser(newUser);
    });

    // ---------------------------------------------------------------

    useEffect(() => {
        if (!user) {
            setCourses();
            setMatches();
            setTournaments();
            setUserData();
            return;
        }

        const scoreCardsNewQuery = query(collection(getFirestore(), 'scoreCards'), where("userId", "==", user.uid));
        const scoreCardsSnapshotUnsubscribe = onSnapshot(scoreCardsNewQuery, querySnapshot => {
            const newScoreCards = getItemsFromQuerySnapshot(querySnapshot);
            const sorted = newScoreCards.sort((a, b) => (a.date < b.date) ? 1 : (a.date > b.date) ? -1 : 0);
            setScoreCards(sorted);
        });

        const scoreCardsInMatchesNewQuery = query(collection(getFirestore(), 'scoreCards'), where("matchId", "!=", ""));
        const scoreCardsInMatchesSnapshotUnsubscribe = onSnapshot(scoreCardsInMatchesNewQuery, querySnapshot => {
            const newScoreCardsInMatches = getItemsFromQuerySnapshot(querySnapshot);
            const sorted = newScoreCardsInMatches.sort((a, b) => (a.date < b.date) ? 1 : (a.date > b.date) ? -1 : 0);
            setScoreCardsInMatches(sorted);
        });

        const newCoursesQuery = query(collection(getFirestore(), 'courses'), orderBy('name', 'asc'));
        const coursesSnapshotUnsubscribe = onSnapshot(newCoursesQuery, courseDocs => {
            const newCourses = courseDocs.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            setCourses(newCourses);
        });

        const matchesNewQuery = query(collection(getFirestore(), 'matches'),
            or(
                where("creatorId", "==", user.uid),
                where("players", "array-contains", user.uid)
            ),
            orderBy('date', 'desc')
        );
        const matchesSnapshotUnsubscribe = onSnapshot(matchesNewQuery, matchesDocs => {
            const newMatches = matchesDocs.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            setMatches(newMatches);
        });

        const usersNewQuery = query(collection(getFirestore(), 'users'));
        const usersSnapshotUnsubscribe = onSnapshot(usersNewQuery, usersDocs => {
            const newUsers = usersDocs.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            setUsers(newUsers);
        });

        const newTournamentsQuery = query(collection(getFirestore(), 'tournaments'), orderBy('name', 'asc'));
        const tournamentsSnapshotUnsubscribe = onSnapshot(newTournamentsQuery, tournamentDocs => {
            const newTournaments = tournamentDocs.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            setTournaments(newTournaments.filter(tournament => tournament.players?.find(player => player.userId === user.uid) || user.uid === tournament.organizer?.id));
        });

        const userDataRef = doc(getFirestore(), 'users', user.uid);
        const userDataSnapshotUnsubscribe = onSnapshot(userDataRef, userDataDoc => {
            const userData = { ...userDataDoc.data(), id: userDataDoc.id };
            setUserData({
                ...userData,
                id: userData.id,
                firstName: userData.firstName,
                lastName: userData.lastName,
                handicap: userData.handicap || 36,
                handicapPitchAndPutt: userData.handicapPitchAndPutt || 36,
                email: userData.email
            });
        });

        return () => {
            scoreCardsSnapshotUnsubscribe();
            scoreCardsInMatchesSnapshotUnsubscribe();
            coursesSnapshotUnsubscribe();
            matchesSnapshotUnsubscribe();
            tournamentsSnapshotUnsubscribe();
            userDataSnapshotUnsubscribe();
            usersSnapshotUnsubscribe();
        }
    }, [user]);

    // ---------------------------------------------------------------

    return (
        <AppContext.Provider value={{ appContext }}>
            {children}
        </AppContext.Provider>
    )
}
