import { doc, getFirestore, setDoc } from 'firebase/firestore';
import React, { useContext, useEffect, useState } from 'react';
import { Dropdown, Nav, NavItem, Navbar } from 'react-bootstrap';
import { BsStopFill } from 'react-icons/bs';
import { MdGolfCourse } from 'react-icons/md';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useGravatar } from 'use-gravatar';
import { AppContext } from '../../Shared/AppContext';
import { isAdmin } from '../../Shared/Calculations';
import { appVersion } from '../../app-version';
import './Header.css';

export const Header = () => {
    const { appContext } = useContext(AppContext);
    const [expanded, setExpanded] = useState(false);
    const [mediaMatchesXs, setMediaMatchesXs] = useState(window.matchMedia("(min-width: 512px)").matches);

    const navigate = useNavigate();
    const userGravatar = useGravatar(appContext.user?.email, 40);

    // ---------------------------------------------------------------

    const handleLogoff = () => {
        setExpanded(false);
        appContext.logoff().then(() => {
            navigate('/');
        })
    }

    const handleSelected = url => {
        setExpanded(false);
        navigate(url);
    }

    const handleInPlayButton = () => {
        setExpanded(false);
        navigate('/score-cards/' + appContext.userData?.inPlay?.scoreCardId + '/play');
    }

    const handleEndPlayButton = async () => {

        const scoreCardId = appContext.userData?.inPlay?.scoreCardId
        const newUserData = {
            ...appContext.userData,
            inPlay: {}
        }
        try {
            const newDoc = doc(getFirestore(), 'users', appContext.user.uid);
            await setDoc(newDoc, newUserData);
        } catch (error) {
            console.log(error);
        }
        navigate('/score-cards/' + scoreCardId);
    }

    // ---------------------------------------------------------------

    useEffect(() => {
        window.matchMedia("(min-width: 512px)").addEventListener('change', (e) => setMediaMatchesXs(e.matches));
    }, []);

    // ---------------------------------------------------------------

    return (<>
        <Navbar expand="sm" variant="light" collapseOnSelect expanded={expanded} className="header-navbar"
            onToggle={newExpand => setExpanded(newExpand)}
        >
            <Navbar.Brand as={Link} to="/" className="fs-2 gap-1 d-flex flex-row align-items-center">
                <img alt="" src="/assets/hcp-down-logo-48x48.png" width="48" height="48" className="me-2 rounded-4" />
                <div className="d-flex flex-row align-items-baseline gap-2">
                    <div className="header-version">v.{appVersion}</div>
                </div>
            </Navbar.Brand>
            {appContext.userData?.inPlay?.scoreCardId &&
                <Nav className="me-auto d-flex flex-row gap-2">
                    <Nav.Link onClick={handleInPlayButton} className="text-white bg-success rounded d-flex flex-row align-items-center ps-2 pe-2 gap-1">
                        {mediaMatchesXs ?
                            'Playing hole ' :
                            <MdGolfCourse />
                        }
                        <div>{(appContext.userData?.inPlay.currentHole + 1)}</div>
                    </Nav.Link>
                    <Nav.Link onClick={handleEndPlayButton} className="bg-success rounded d-flex flex-row align-items-center ps-2 pe-2 gap-1">
                        &nbsp;<BsStopFill />{mediaMatchesXs ? 'End' : ''}&nbsp;
                    </Nav.Link>
                </Nav>
            }
            <Nav className="d-flex flex-column flex-sm-row gap-2">
                {appContext.user?.uid && <>
                    <Nav.Link onClick={() => handleSelected('/dashboard')}>DASHBOARD</Nav.Link>
                </>}
            </Nav>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                <Nav className="d-flex flex-column flex-sm-row gap-2">
                    {appContext.user?.uid && <>
                        <Nav.Link onClick={() => handleSelected('/score-cards')}>SCORE CARDS</Nav.Link>
                        <Nav.Link onClick={() => handleSelected('/matches')}>MATCHES</Nav.Link>
                        <Nav.Link onClick={() => handleSelected('/tournaments')}>TOURNAMENTS</Nav.Link>
                        <Nav.Link onClick={() => handleSelected('/calendar')}>CALENDAR</Nav.Link>
                        <Nav.Link onClick={() => handleSelected('/statistics')}>STATISTICS</Nav.Link>
                        <Nav.Link onClick={() => handleSelected('/courses')}>COURSES</Nav.Link>
                    </>}

                    {appContext.user?.uid &&
                        <>
                            <Nav.Link onClick={() => handleSelected('/my-bag')}>MY BAG</Nav.Link>
                            <Dropdown as={NavItem} id="basic-nav-dropdown" align="end">
                                <Dropdown.Toggle as={NavItem}>
                                    <img className="avatar-img rounded-4" src={userGravatar} alt="user avatar" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => handleSelected('/profile')}>Profile</Dropdown.Item>
                                    <Dropdown.Divider />
                                    {isAdmin(appContext.user.uid) && <>
                                        <Dropdown.Item onClick={() => handleSelected('/export')}>Export</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleSelected('/users')}>Users</Dropdown.Item>
                                        <Dropdown.Divider />
                                    </>}
                                    <Dropdown.Item onClick={handleLogoff}>Logoff</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </>
                    }
                    {!appContext.user?.uid && <>
                        <Nav.Link onClick={() => handleSelected('/login')}>Login</Nav.Link>
                        <Nav.Link onClick={() => handleSelected('/register')}>Register</Nav.Link>
                    </>}
                </Nav>
            </Navbar.Collapse>
        </Navbar >
    </>)
}