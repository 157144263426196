import { BsArrowBarDown, BsArrowBarUp, BsArrowUpLeft, BsArrowUpRight, BsCheckCircleFill, BsCircle, BsEmojiFrown } from "react-icons/bs";

export const fairwayIcons = [
    { value: 0, icon: <BsCircle size="16" />, title: 'No data', },
    { value: 2, icon: <BsArrowUpLeft size="16" />, title: 'Gone left' },
    { value: 1, icon: <BsCheckCircleFill style={{ color: 'green' }} size="18" />, title: 'Fairway/Green', },
    { value: 3, icon: <BsArrowUpRight size="16" />, title: 'Gone right' },
    { value: 4, icon: <BsEmojiFrown size="16" />, title: 'Bad shot!!' },
    { value: 5, icon: <BsArrowBarDown size="16" />, title: 'Missed short' },
    { value: 6, icon: <BsArrowBarUp size="16" />, title: 'Missed long' }
];

export const getFairwayIcon = fairway => {
    return fairwayIcons.find(fi => fi.value === fairway);
};