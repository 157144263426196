import 'bootstrap/dist/css/bootstrap.min.css';

import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css';

// Generated with https://themestr.app
import './theme_1655193470052.css';
// import './theme_1655194675484.css';

import { initializeApp } from 'firebase/app';
// import { connectAuthEmulator, getAuth } from 'firebase/auth';
// import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import React from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
// import ReactDOM from 'react-dom';
import { initializeFirestore, CACHE_SIZE_UNLIMITED, getFirestore, persistentLocalCache } from 'firebase/firestore';
import { createRoot } from 'react-dom/client';
import './index.css';
import AppRoutes from './routes';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    databaseURL: 'localhost:09097',
    apiKey: "AIzaSyCEBb6p0TAhiCYSYbiUu-5WN0B01CkrS70",
    authDomain: "golf-scores-5dd4a.firebaseapp.com",
    projectId: "golf-scores-5dd4a",
    storageBucket: "golf-scores-5dd4a.appspot.com",
    messagingSenderId: "1099041374126",
    appId: "1:1099041374126:web:7b3ac763327ecad62b523f",
    measurementId: "G-2VM2XCWTZY"
};

// initializeApp(firebaseConfig, {
//     localCache: persistentLocalCache(
//         {
//             tabManager: persistentMultipleTabManager()
//         }
//     )
// });
// initializeApp(firebaseConfig);
const firebaseApp = initializeApp(firebaseConfig, {
    localCache: persistentLocalCache({})
})

initializeFirestore(firebaseApp, {
    cacheSizeBytes: CACHE_SIZE_UNLIMITED
});

const indexManager = getFirestore().persistentCacheIndexManager;
if (indexManager) {
    // Indexing is disabled by default
    indexManager.enableIndexAutoCreation()
    console.info("indexManager enabled.")
} else {
    console.info("indexManager is nil")
}

//getFirestore().settings({ experimentalForceLongPolling: true }); //add this..

//connectFirestoreEmulator(getFirestore(), 'localhost', 9097);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

// ReactDOM.render(<AppRoutes />, document.getElementById('root'));

const root = createRoot(document.getElementById('root'))
root.render(<AppRoutes />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
