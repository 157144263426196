import React from 'react';
import { Header } from '../Header/Header';
// import { Footer } from '../Footer/Footer';
import './Layout.css';
import { ServiceWorkerWrapper } from './ServiceWorkerWrapper';

export const Layout = props => {

  return (
    <div className="layout-container">
      <ServiceWorkerWrapper />
      <Header />
      <main className="p-0 pt-md-3 ps-md-3 pe-md-3">
        {props.children}
      </main>
    </div>
  );
}
