import React, { useEffect } from 'react';
import { Button, Toast, ToastContainer } from 'react-bootstrap';
import { BsArrowClockwise, BsXLg } from 'react-icons/bs';
import * as serviceWorker from '../../serviceWorkerRegistration';

// ----------------------------------------------------------------

export const ServiceWorkerWrapper = () => {
    // ----------------------------------------------------------------

    const [showReload, setShowReload] = React.useState(false);
    const [waitingWorker, setWaitingWorker] = React.useState(null);

    // ----------------------------------------------------------------

    const invokeServiceWorkerUpdateFlow = (registration) => {
        setShowReload(true);
        setWaitingWorker(registration.waiting);
    };

    const reloadPage = () => {
        waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
        setShowReload(false);
        window.location.reload(true);
    };

    // ----------------------------------------------------------------

    const handleClose = () => {
        setShowReload(false);
    }

    // ----------------------------------------------------------------

    useEffect(() => {
        const register = async () => {
            const registration = await serviceWorker.register({ onUpdate: invokeServiceWorkerUpdateFlow });

            if (registration) {
                // ensure the case when the updatefound event was missed is also handled
                // by re-invoking the prompt when there's a waiting Service Worker
                if (registration.waiting) {
                    invokeServiceWorkerUpdateFlow(registration)
                }

                // detect Service Worker update available and wait for it to become installed
                registration.addEventListener('updatefound', () => {
                    if (registration.installing) {
                        // wait until the new Service worker is actually installed (ready to take over)
                        registration.installing.addEventListener('statechange', () => {
                            if (registration.waiting) {
                                // if there's an existing controller (previous Service Worker), show the prompt
                                if (navigator.serviceWorker.controller) {
                                    invokeServiceWorkerUpdateFlow(registration)
                                } else {
                                    // otherwise it's the first install, nothing to do
                                    console.log('Service Worker initialized for the first time')
                                }
                            }
                        })
                    }
                })
            }

            let refreshing = false;

            // detect controller change and refresh the page
            navigator.serviceWorker.addEventListener('controllerchange', () => {
                if (!refreshing) {
                    window.location.reload()
                    refreshing = true
                }
            })
        }
        register();
    }, []);

    // ----------------------------------------------------------------

    return (<>
        {/*
            <Button onClick={() => setShowReload(true)}>Reload</Button>
        */}
        <ToastContainer position='top-end' style={{ zIndex: '1000', padding: '16px' }}>
            <Toast show={showReload} onClose={handleClose}>
                <Toast.Header><strong className="me-auto">New version is available!!!!!</strong></Toast.Header>
                <Toast.Body>
                    <div className="mb-4">Click on Reload button to use the new version.</div>
                    <div className="d-flex flex-row justify-content-around">
                        <Button onClick={reloadPage} className="d-flex align-items-center gap-2"><BsArrowClockwise />Reload</Button>
                        <Button variant="" onClick={handleClose} className="d-flex align-items-center gap-2"><BsXLg /> Not now</Button>
                    </div>
                </Toast.Body>
            </Toast>
        </ToastContainer>
    </>);
}
